import axios from 'axios'
import {
	Toast,
	showLoadingToast
} from 'vant';
import {
	showToast
} from 'vant';
import 'vant/lib/index.css';

import {
	showDialog
} from 'vant';






// 创建axios实例
const service = axios.create({
	baseURL: process.env.VUE_APP_BASE_API, // api的base_url
	timeout: 15000, // 请求超时时间

})

// request拦截器
service.interceptors.request.use(config => {
	var sanmark_access_token = localStorage.getItem("sanmark_access_token") ? localStorage.getItem("sanmark_access_token") : '';
	config.headers['Authorization'] = sanmark_access_token // 让每个请求携带自定义token 请根据实际情况自行修改
	// config.headers['content-type'] = 'application/json

	return config
}, error => {
	// Do something with request error
	console.log(error) // for debug
	Promise.reject(error)
})

// respone拦截器
service.interceptors.response.use(
	response => {
		/**
		 * code为非200是抛错 可结合自己业务进行修改
		 */
		const res = response.data
		if (res.code !== 200 && res.code !== 300) {
			showDialog({
				title: '提示',
				message: res.message
			});
		} else {
			return response.data
		}
	},
	error => {
		console.log('err' + error) // for debug
		showDialog({
			title: '提示',
			message: error.message
		});
		return Promise.reject(error)
	}
)

export default service